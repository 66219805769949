<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    :type="type"
    :loading="loading"
    :block="block"
    :outlined="variant === 'outlined'"
    :raised="variant === 'raised'"
    :depressed="depressed"
    :fab="fab"
    :icon="icon"
    :plain="variant === 'plain'"
    :small="size === 'small'"
    :large="size === 'large'"
    :x-small="size === 'x-small'"
    :x-large="size === 'x-large'"
    :style="btnStyle"
    :disabled="disabled"
    :ripple="ripple"
    :elevation="elevation"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    elevation: {
      type: [String, Number],
      default: 0,
    },
    type: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "rawesc",
    },
    fab: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fclr: {
      type: String,
      default: "white",
    },
    width: {
      type: String,
      default: "120px",
    },
    height: {
      type: String,
      default: "48px",
    },
    textCase: {
      type: String,
      default: "capitalize",
    },
    variant: {
      type: String,
      default: "raised",
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnStyle() {
      return {
        color: this.fclr,
        width: this.width,
        height: this.height,
        "text-transform": this.textCase,
      };
    },
  },
};
</script>
