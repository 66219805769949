<template>
  <div class="base-file-input my-4 py-4" :style="baseFileInputCss" @drop.prevent="dropFile" @dragover.prevent>
    <v-img :src="uploadPlaceholder" :alt="alt" height="200" max-width="250"
      class="ma-0" />

    <div v-if="!selectedImg" class="d-flex align-center mb-5">
      <ReBtn style="background-color: #d09c57" width="70px" height="25px" class="font-weight-medium mx-2" @click="$refs.file.$refs.input.click()">
        Upload
      </ReBtn>
      or Drag and Drop here
    </div>
    <section class="d-flex align-center">
      <v-form ref="fileInputForm" v-model="isFileUploaded">
        <v-file-input ref="file" v-model="addedImageDetails" hide-input prepend-icon="" @change="onUploadFile"
          v-on="$listeners" v-bind="$attrs" />
      </v-form>
    </section>
  </div>
</template>

<script>
import fileUploadPlaceholder from "@/assets/file-upload.png";
import ReBtn from "./ReBtn";

export default {
  name: "ReFileInputCard",
  props: {
    value: {
      type: [String, Array, File, Object, DragEvent],
      default: () => [],
    },
    src: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    height: {
      type: [Number, String],
      default: "240px",
    },
    width: {
      type: [Number, String],
      default: 800,
    },
    alt: {
      type: String,
      default: "upload",
    },
  },
  data: () => ({
    isFileUploaded: true,
    uploadPlaceholder: `${fileUploadPlaceholder}`,
    selectedImg: "",
    addedImageDetails: null
  }),
  computed: {
    baseFileInputCss() {
      const fileInputCss = {
        height: this.height,
        width: this.width,
        display: "flex",
        "flex-direction": !this.selectedImg ? "column" : "row",
        "justify-content": "center",
        "align-items": "center",
        "padding-top": !this.selectedImg ? "25px" : "10px",
      };
      return fileInputCss;
    },
  },
  async mounted() {

  },
  methods: {
    dropFile(file) {
      const fileData = file.dataTransfer.files[0];
      if (fileData?.size) {
        //this.selectedImg = URL.createObjectURL(fileData);
        this.addedImageDetails = fileData;
      }
      console.log('drop file', this.selectedImg, this.addedImageDetails);
      this.$emit("imageUpload", this.addedImageDetails);
    },
    async onUploadFile(file) {
      if (file?.size) {
        console.log(typeof file);
        //this.selectedImg = URL.createObjectURL(file);
        this.addedImageDetails = file;
      }
      console.log('upload file', this.selectedImg, this.addedImageDetails);
      this.$emit("imageUpload", this.addedImageDetails);
    },
    onRemoveFile() {
      this.inputValue = null;
      this.selectedImg = null;
      console.log('remove file', this.selectedImg, this.isFileUploaded);
      this.$emit("imageUpload", this.selectedImg);
    },
  },
  components:{
    ReBtn: ReBtn
  }
};
</script>

<style>
.base-file-input {
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
}

.base-file-input:before {
  content: "";
  position: absolute;
  border: 5px dashed #d09c57 !important;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
}
</style>