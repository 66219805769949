<template>
    <div>
        <v-dialog v-model="dialog" max-width="450px" persistent>
            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                                <v-img
                                        contain
                                        max-height="150"
                                        :src="selectedImage"
                                ></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                        label="Thumbnail Image"
                                        outlined
                                        dense
                                        @change="getImage"
                                        v-model="editedItem.thumbnail_url"
                                        prepend-icon=""
                                        append-icon="mdi-image"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        outlined
                                        dense
                                        v-model="editedItem.folder_name"
                                        :error-messages="nameErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.folder_name.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.folder_name.$touch(): null"
                                        label="Name"
                                        append-icon="mdi-rename-box"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{error}}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="success" @click="save">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text"
                >
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="success" @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters>
            <v-col cols="12" sm="5">
                <ReFileInputCard
                        v-model="selectedImageUrl"
                        ref="packageImage"
                        required
                        :src="selectedImage"
                        :key="re"
                        className="d-flex align-center justify-center"
                        @imageUpload="getImage"/>
            </v-col>
        </v-row>
        <v-container style="max-width: 72vw">
            <Drag v-model="images" draggable=".folder_image_items" @start="drag=true" @end="onDragEnd" class="row">
                <v-col cols="sm-6 col-md-4 col-lg-3" :key="img.id" v-for="img in images" class="folder_image_items">
                    <v-container style="position: relative" class="pa-2">
                        <button class="delete_folder" @click="deleteItem(img)">
                            <i aria-hidden="true" class="v-icon notranslate mdi mdi-delete-outline theme--light"
                               style="color: red"></i>
                        </button>

                        <v-img
                                cover
                                max-height="180"
                                width="100%"
                                :src="img.daily_image_url"
                                style="border-radius: 8px;"
                        ></v-img>
                    </v-container>
                </v-col>
            </Drag>
        </v-container>
    </div>
</template>
<script>
    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from 'vuelidate'
    import ReFileInputCard from "../components/form/ReFileInputCard";
    import draggable from 'vuedraggable'

    export default {
        name: 'DailyImageFolderImage',
        mixins: [validationMixin],
        data: () => ({
            re: 0,
            imgdialog: false,
            selectedSrc: null,
            images: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            editedIndex: -1,
            selectedImageUrl: null,
            editedItem: {
                folder_id: '',
                daily_image_url: null
            },
            defaultItem: {
                folder_id: '',
                daily_image_url: null
            },
            selectedImage: null,
        }),
        created() {
            this.getItems();
        },
        methods: {
            onDragEnd(item) {

                let updateData = {
                    order_sequence: item.newIndex + 1
                };
                this.$Axios({
                    method: 'put',
                    url: 'nettazy-daily/' + this.images[item.newIndex].id,
                    data: updateData,
                })
                    .then(() => {
                        this.getItems();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            openImage(src) {
                this.selectedSrc = src
                this.imgdialog = true
            },
            editItem(item) {
                this.editedIndex = this.images.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.popup_image_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.images.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deleteImageItem(this.editedItem.id)
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$v.$reset()
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.editedIndex == -1 ? this.addItem() : this.updateItem()
            },
            //Banners API Calls
            async getImage(addedImageDetails) {
                try {
                    this.selectedImageUrl = addedImageDetails;

                    console.log('base image');
                    let formData = new FormData();
                    formData.append('daily_file', this.selectedImageUrl);

                    let data = await this.$Axios({
                        method: 'post',
                        url: 'nettazy-daily/add-daily-image',
                        data: formData,
                        headers: {'Content-Type': 'multipart/form-data'},
                    });

                    console.log(data.data);
                    if (data.data.success) {
                        this.selectedImageUrl = data.data.data.daily_image_url;
                        await this.addItem();

                        let insertData = {}
                        insertData.daily_image_url = this.selectedImageUrl;
                        insertData.folder_id = this.folderId;

                        let insRes = await this.$Axios
                            .post('nettazy-daily', insertData);

                        console.log(insRes);
                        await this.getItems();
                        this.close();
                    } else {
                        console.log(data.data.msg);
                        alert(data.data.msg);
                    }
                } catch (e) {
                    console.log(e);
                    alert(e.response?.data?.msg ?? 'Something went wrong!');
                }
            },
            getItems() {

                this.loading = true
                this.$Axios
                    .get('nettazy-daily/get/all/' + this.folderId)
                    .then((res) => {
                        if (res.data['success']) {
                            this.images = res.data['data'];
                        } else {
                            alert(res.data['msg']);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            addItem() {

                this.error = ''
                this.$v.$touch()
                console.log(this.$v)
                if (this.$v.$error) {
                    return;
                }
                let data = {}
                data.daily_image_url = this.selectedImageUrl;
                data.folder_id = this.folderId;

                this.$Axios
                    .post('nettazy-daily', data)
                    .then(() => {
                        this.getItems();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            updateItem() {
                this.error = ''
                this.$v.$touch()

                if (this.$v.$error) {
                    return
                }
                let updateData = {};
                if (this.editedItem.folder_name) updateData.folder_name = this.editedItem.folder_name;
                if (this.editedItem.thumbnail_url) updateData.thumbnail_url = this.editedItem.thumbnail_url;
                console.log('adding item : ', updateData);
                this.$Axios({
                    method: 'put',
                    url: 'nettazy-daily/folder/' + this.editedItem.id,
                    data: updateData,
                })
                    .then(() => {
                        this.getItems();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            deleteImageItem(id) {
                this.$Axios
                    .delete('nettazy-daily/' + id)
                    .then(() => {
                        this.getItems();
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
            redirectToFolderDetailsView() {
                this.$router.push('/overview')
            }
        },
        computed: {
            folderId() {
                return this.$route.params.folderId;
            },
            formTitle() {
                return this.editedIndex === -1 ? 'New Folder' : 'Edit Folder';
            },
            formIcon() {
                return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
            },
            filtredItems() {
                if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
                else return this.desserts
            },
            //// Vuelidate Errors
            nameErrors() {
                const errors = []
                if (!this.$v.editedItem.folder_name.$dirty) return errors
                !this.$v.editedItem.folder_name.required && errors.push('Name is required !')
                return errors
            },
            img_urlErrors() {
                const errors = []
                if (!this.$v.editedItem.popup_image_url.$dirty) return errors
                !this.$v.editedItem.popup_image_url.required && errors.push('Popup Image is required !')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        validations: {
            editedItem: {
                folder_name: {
                    required
                },
                thumbnail_url: {
                    required,
                }
            },
        },
        components: {
            ReFileInputCard: ReFileInputCard,
            Drag: draggable
        }
    };
</script>
<style scoped>
    button.delete_folder {
        position: absolute;
        top: 30px;
        right: 10px;
        z-index: 9;
        background: #fff;
        border-radius: 50%;
        padding: 7px;
        box-shadow: 0 0 8px 2px #ccc;
    }

    button.edit_folder {
        position: absolute;
        top: 75px;
        right: 10px;
        z-index: 9;
        background: #fff;
        border-radius: 50%;
        padding: 7px;
        box-shadow: 0 0 8px 2px #ccc;
    }
</style>

<style scoped>
    button.delete_folder {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 9;
        background: #fff;
        border-radius: 50%;
        padding: 7px;
        box-shadow: 0 0 8px 2px #ccc;
    }
</style>